.title {
  width: 100%;
  font-family: var(--title-font);
  text-align: center;
}

.title h1 {
  margin: 0;
  font-size: var(--H3);
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-left: -0.3rem;
}
.title p {
  margin: 0;
  font-size: var(--body-medium);

  font-weight: 400;
}

@media (min-width: 560px) {
  .title {
    text-align: start;
  }

  .title h1 {
    font-size: var(--H1);
    letter-spacing: 0.2rem;
  }
  .title p {
    font-size: var(--body-big);
    letter-spacing: 0.1rem;
  }
}
