.main {
  box-sizing: border-box;
  margin: 1.6rem;
}
@media (min-width: 560px) {
  .main {
    box-sizing: border-box;
    margin: 3.2rem;
  }
}
