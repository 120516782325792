.animation {
  position: fixed;
  top: -15vw;
  left: -15vw;
  z-index: -10;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.element {
  /* background-color: red; */
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  position: absolute;
  transition: transform 8s linear;
}

#orange {
  background-image: radial-gradient(
    circle,
    var(--white) 0%,
    var(--orange) 100%
  );
}
#yellow {
  background-image: radial-gradient(
    circle,
    var(--white) 0%,
    var(--yellow) 100%
  );
}
#blue {
  background-image: radial-gradient(circle, var(--white) 0%, var(--blue) 100%);
}
