@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --dark: rgb(20, 20, 20);
  --black: rgb(0, 0, 0);
  --gray: rgb(255, 255, 255);
  --light: rgb(240, 240, 240);
  --white: rgb(255, 255, 255);
  --white-gradient: rgba(250, 250, 250, 0.8);
  --blue: rgba(136, 162, 242, 0.5);
  --yellow: rgba(242, 207, 29, 0.7);
  --orange: rgba(242, 98, 46, 0.7);
  --shadow: 0.5rem 0.5rem 1rem rgba(130, 129, 129, 0.3);
  --border: 0.2rem solid var(--white);

  --title-font: "Michroma", sans-serif;
  --body-font: "IBM Plex Serif", serif;

  --H1: 4.2rem;
  --H2: 3.2rem;
  --H3: 2.4rem;
  --body-big: 1.6rem;
  --body-medium: 1.4rem;
  --body-small: 1.2rem;
  --media-tablet: 560px;
}
html {
  font-size: 10px;
}
body {
  max-width: 1124px;

  min-width: 36rem;
  margin: 0 auto;
  font-family: var(--body-font);
  background-color: var(--light);
}
