.contacts {
  margin: 1.6rem 0;
  font-size: var(--body-medium);
  line-height: 2rem;
  background-color: var(--white-gradient);
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: var(--border);
  box-shadow: var(--shadow);
}
.socialContacts {
  width: 100%;
}
.socialContacts ul {
  max-width: 56rem;
  margin: 0 auto;
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  margin-bottom: 2.4rem;
}

.socialContacts ul li {
  width: 25%;
  padding: 0.8rem;
  border-radius: 0.8rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--body-small);
  font-weight: 600;
}

.socialContacts ul li a {
  display: block;
  color: var(--black);
  font-size: var(--H3);
  margin-bottom: 0.4rem;
}
.socialContacts ul li a:hover {
  transform: scale(1.1);
}

@media (min-width: 560px) {
  .contacts {
    padding: 2.4rem;
  }
  .socialContacts ul {
    justify-content: space-between;
    gap: 1.6rem;
    margin-bottom: 3.2rem;
  }
  .socialContacts ul li {
    font-size: var(--body-medium);
    padding: 1.6rem;
  }
  .socialContacts ul li a {
    font-size: var(--H2);
    margin-bottom: 0.8rem;
  }
}
