.form {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  max-width: 56rem;
  margin: 0 auto;
}
.form label {
  font-weight: 600;
  font-size: var(--body-medium);
  margin-bottom: 0.8rem;
}
.form textarea {
  box-sizing: border-box;
  padding: 1.6rem;
  width: 100%;
  margin-bottom: 1.6rem;
  border: none;
  border-radius: 0.8rem;
}
.form button {
  align-self: flex-end;
  border: none;
  background-color: var(--white);
  font-size: var(--body-medium);
  width: fit-content;
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem;
}
.form button:hover {
  box-shadow: var(--shadow);
}
@media (min-width: 560px) {
  .form label {
    font-size: var(--body-big);
    margin-bottom: 1.6rem;
  }
  .form textarea {
    margin-bottom: 2.4rem;
  }
  .form button {
    padding: 1.6rem 3.2rem;
    font-size: var(--body-big);
    font-weight: 600;
  }
}
