.intro {
  margin: 1.6rem 0;
  font-size: var(--body-medium);
  line-height: 2rem;
  background-color: var(--white-gradient);
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: var(--border);
  box-shadow: var(--shadow);
}

@media (min-width: 560px) {
  .intro {
    font-size: var(--body-big);
    line-height: 2.4rem;
    padding: 2.4rem;
  }
}
