.header {
  display: flex;
  align-items: center;
  height: 15vh;
  margin: 1.6rem;
}
@media (min-width: 560px) {
  .header {
    height: 20vh;
    margin: 3.2rem;
  }
}
