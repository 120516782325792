/* Social */
.social {
  width: 100%;
  background-color: var(--white-gradient);
  box-shadow: var(--shadow);
}
.social ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
  list-style: none;
}

.social ul li {
  padding: 1.6rem;
}
.social ul li:hover {
  transform: scale(1.1);
}

.social ul li a {
  font-size: var(--H3);
  color: var(--black);
}

@media (min-width: 560px) {
  .social {
    background-color: transparent;
    box-shadow: none;
  }
  .social ul {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;

    padding: 0 2.6rem;
  }

  .social ul li a {
    color: var(--black);
    font-size: var(--H3);
  }
}
