.project {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white-gradient);
  border-radius: 1.6rem;
  overflow: hidden;
  box-shadow: var(--shadow);
  border: var(--border);
}

.content {
  padding: 1.6rem;
  flex-grow: 2;
}
.image {
  width: 100%;
  box-shadow: var(--shadow);
}
.image img {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.8rem 0.8rem 0 0;
  height: 100%;
  object-fit: contain;
  object-position: top;
  /* border: 0.8rem solid var(--white); */
}

.title {
  font-family: var(--body-font);
  font-size: var(--H3);
  font-weight: 600;
  margin: 0;
  margin: 0 0 1.6rem 0;
}

.details {
  list-style: none;
  padding-left: 0;
  font-size: var(--body-small);
  margin: 0;
  margin-bottom: 1.6rem;
}

.bold {
  font-weight: 600;
}
.description {
  font-size: var(--body-medium);
  font-weight: 400;
  margin: 0;
  margin-bottom: 0.8rem;
  line-height: 1.8rem;
}

.stack {
  font-size: var(--body-small);
  font-weight: 400;
  margin: 0;
}
.link {
  font-size: var(--body-small);
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  margin: 0 1.6rem 1.6rem 1.6rem;
}
.link a {
  color: var(--black);
  text-decoration: none;
  padding: 0.8rem;
  font-size: var(--body-small);
  background-color: var(--white);
  border-radius: 0.8rem;
  margin: 0.4rem;
}
.link a:hover {
  box-shadow: var(--shadow);
  text-decoration: underline;
}

@media (min-width: 520px) {
  .content {
    padding: 2.4rem;
  }

  .details {
    font-size: var(--body-medium);

    margin-bottom: 2.4rem;
  }

  .bold {
    font-weight: 600;
  }
  .description {
    font-size: var(--body-big);
    margin-bottom: 1.6rem;
    line-height: 2rem;
  }

  .stack {
    font-size: var(--body-medium);
    font-weight: 400;
    margin: 0;
  }
  .link {
    font-size: var(--body-medium);
    margin: 0 2.4rem 2.4rem 2.4rem;
  }
  .link a {
    margin: 0 1.6rem 0 0;
  }
  .link a:hover {
    /* color: var(--darck); */
    text-decoration: underline;
  }
}
