.title {
  font-family: var(--title-font);
  font-size: var(--H3);
  font-weight: 400;
  margin: 0;
  margin-top: var(--H2);
}
hr {
  margin: 1.4rem 0 2.8rem 0;
  border: 0.1rem solid var(--dark);
}

@media (min-width: 560px) {
  .title {
    font-family: var(--title-font);
    font-size: var(--H2);
    font-weight: 400;
    margin: 0;
    margin-top: var(--H1);
  }
}
