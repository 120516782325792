.skills {
  font-family: var(--body-font);
  font-size: var(--body-medium);
  line-height: 2rem;
}
.skills ul {
  background-color: var(--white-gradient);
  border-radius: 1.6rem;
  padding: 1.6rem 1.6rem 1.6rem 3.2rem;
  box-shadow: var(--shadow);
  border: var(--border);
}
.skills ul li {
  line-height: 2rem;
  margin-bottom: 0.35rem;
}
.bold {
  display: block;
  font-weight: 700;
}

@media (min-width: 560px) {
  .skills {
    font-size: var(--body-big);
  }
  .skills ul {
    padding: 2.4rem 2.4rem 2.4rem 4rem;
  }
  .skills ul li {
    line-height: 2.4rem;
    margin-bottom: 0.8rem;
  }
  .bold {
    display: inline-block;
    font-weight: 700;
  }
}
