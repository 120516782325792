.projects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.6rem;
}
@media (min-width: 560px) {
  .projects {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 3.2rem;
  }
}
